import React from 'react'
import Layout from '../components/layout'
import TileComponent from '../components/Tile'

export const TileItem = (data) => (
  <div className='col-lg-4 col-md-6'>
    <TileComponent {...data} />
  </div>
)

const CategoryLayout = ({ pageContext, markdownRemark, location, children }) => {
  const frontmatter = pageContext.frontmatter || markdownRemark.frontmatter

  return (
    <Layout {...{ location }}>
      {children}
      <div className='container'>
        <div className='section-heading'>
          <h2>{frontmatter.title}</h2>
        </div>
        <div className='row'>
          {frontmatter.tiles && frontmatter.tiles.map((data, idx) => <TileItem {...data} key={idx} />)}
        </div>
      </div>
    </Layout>
  )
}

CategoryLayout.defaultProps = {
  pageContext: {},
  markdownRemark: {},
  frontmatter: {}
}

export default CategoryLayout
