import React from 'react'
import styled from 'styled-components'
import { Theme } from '@pga/pga-component-library'

const HeroContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: flex-start;
  margin-bottom: 2rem;
`

const HeroImage = styled.img`
  height: auto;
  max-width: 100%;
  @media (max-width: 575.98px) {
    transform: translate(-20%, 0);
  }
  @media (max-width: 767.98px) {
    height: 350px;
    max-width: none;
  }
  @media (max-width: 367.98px) {
    height: 400px;
  }
  @media (min-width: 1200px) {
    width: 100%;
  }
`

const Hero = styled.div`
  position: relative;
  overflow: hidden;
  @media (min-width: 1400px) {
    height: 500px;
  }
  &:after {
    background-image: ${`linear-gradient(180deg, ${Theme.colors.primaryOpaque}, ${Theme.colors.primary})`};
    bottom: 0;
    content: "";
    left: 0;
    opacity: .7;
    position: absolute;
    right: 0;
    top: 70%;
  }

`

const HeroBody = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
`

export default ({ heroImageUrl, children }) => (
  <HeroContainer>
    <Hero>
      <HeroImage src={heroImageUrl} />
      <HeroBody>
        {children}
      </HeroBody>
    </Hero>
  </HeroContainer>
)
