import React, { useState } from 'react'
import styled from 'styled-components'
import { Container, Modal, ModalBody } from 'reactstrap'
import { graphql } from 'gatsby'
import { path } from 'ramda'

import Layout from '../../layouts/category'
import HeroImageWithText from '../../components/HeroImageWithText'
import { PlayIconWhite } from '@pga/pga-component-library'

const TextWrapper = styled.div`
  h3 {
    color: white;
    font-size: 32px;
  }
  p {
    color: white;
    @media (min-width: 992px) {
      width: 30%;
    }
  }
`

const VideoButton = styled.button`
  padding: 0 20px 0 0;
  font-size: 0.75rem;
  line-height: 1.2;
  color: white;
  background-color: transparent;
  border-color: white;
  cursor: pointer;
  i {
    padding: 0;
  }
`

export const ModalContent = ({ modalOpen, modalToggle, videoLink }) => {
  return (
    <Modal isOpen={modalOpen} toggle={modalToggle} centered size='lg'>
      <ModalBody>
        <div className='embed-responsive embed-responsive-16by9'>
          <iframe frameBorder='0' src={videoLink} width='640' height='360' allowFullScreen webkitallowfullscreen mozallowfullscreen />
        </div>
      </ModalBody>
    </Modal>
  )
}

export default ({ location, data }) => {
  const { markdownRemark } = data
  const heroImageUrl = path(['frontmatter', 'hero_image'], markdownRemark)
  const videoLink = path(['frontmatter', 'hero_video_link'], markdownRemark)

  const [modalOpen, setModalOpen] = useState(false)
  const modalToggle = () => setModalOpen(!modalOpen)

  return (
    <Layout {...{ location, markdownRemark }}>
      <HeroImageWithText {...{ heroImageUrl }}>
        <Container>
          <TextWrapper>
            <h3>Lifelong Learning</h3>
            <p>
              The purpose of the new PGA Learning Strategy is to provide Lifelong Learning around specific career interests to elevate the perception, value propositions and recruitment of PGA Professionals. Thus, the three career paths offered in the PGA Learning Strategy include: Teaching/Coaching, Golf Operations, and Executive Management.
            </p>
            {/* <VideoButton onClick={modalToggle}>
              <PlayIconWhite />
              Watch the video
            </VideoButton> */}
          </TextWrapper>
          <ModalContent {...{ modalOpen, modalToggle, videoLink }} />
        </Container>
      </HeroImageWithText>
    </Layout>
  )
}

export const query = graphql`
  query {
    markdownRemark(
      fields: {
        slug: {
          eq: "/lifelong-learning/"
        }
      }
    ) {
      frontmatter {
        tiles {
          title
          image
          link
        }
        title
        hero_image
        layout
        permalink
      }
    }
  }
`
